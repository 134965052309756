import React, { ComponentProps } from 'react';
import { Divider } from '@material-ui/core';
import { TreeView } from '@material-ui/lab';
import { PowerSettingsNew } from '@material-ui/icons';
import styled from 'styled-components';
import { SideNavItem } from '../fragments';
import { SideNav } from './';
import { Constants } from '~/libs';

/* -------------------- DOM -------------------- */
type Props = Partial<
  Pick<ComponentProps<typeof SideNavItem>, 'bgColor' | 'textColor'> & {
    onItemClick: () => void;
  }
>;

const Ui: React.FCX<Props> = ({ bgColor, textColor, onItemClick, className }) => (
  <>
    <SideNav
      bgColor={bgColor}
      textColor={textColor}
      onItemClick={onItemClick}
      className={className}
    />
    <Divider />
    <TreeView defaultEndIcon={EmptyIcon} className={`${className} other-series`}>
      <SideNavItem
        bgColor={bgColor}
        textColor={textColor}
        onClick={onItemClick}
        labelText={'4T'}
        nodeId="1"
        externalLink={{
          href: Constants.externalRoutes.fourT,
          target: '_blank',
        }}
      />
      <SideNavItem
        bgColor={bgColor}
        textColor={textColor}
        onClick={onItemClick}
        labelText={'4F'}
        nodeId="2"
        externalLink={{
          href: Constants.externalRoutes.fourF,
          target: '_blank',
        }}
      />
      <SideNavItem
        bgColor={bgColor}
        textColor={textColor}
        onClick={onItemClick}
        labelText={'4G'}
        nodeId="3"
        externalLink={{
          href: Constants.externalRoutes.fourG,
          target: '_blank',
        }}
      />
    </TreeView>
    <Divider />
    <TreeView defaultEndIcon={EmptyIcon} className={`${className} logout`}>
      <SideNavItem
        labelIcon={PowerSettingsNew}
        bgColor={bgColor}
        textColor={textColor}
        onClick={onItemClick}
        labelText={'logout'}
        nodeId="1"
        externalLink={{
          href: Constants.externalRoutes.logout,
          target: '_blank',
        }}
      />
    </TreeView>
  </>
);

const EmptyIcon = <div className="empty-icon" />;

/* ------------------- Style ------------------- */
const StyledUi: React.FCX<Props> = styled(Ui)`
  div[class*='MuiTreeItem-iconContainer'] {
    margin-left 3px;
  }

  .MuiTreeItem-label {
    padding-right: ${(props) => props.theme.spacing(2.5)}px;
  }

  &.other-series > li, &.logout > li {
    margin-top: ${(props) => props.theme.spacing(1)}px;
  }

  .empty-icon {
    width: 24px;
  }
`;

/*---------------------------------------------- */
export default StyledUi;
